<template>
  <v-container grid-list-lg>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <div
        v-resize="onResize"
        class="height-auto"
        :style="setAutoHeight">
        <v-layout
          class="layout-block"
          row
          wrap>
          <edit-actions
            v-if="actionsEdit"
            :valid="valid"
            :display-mode="isDisplayMode"
            @change="changeDisplay"
            @save="save">
            <template v-slot:extra-actions>
              <slot name="extra-actions" />
            </template>
          </edit-actions>

          <!-- <v-flex xs12 sm12 class="full-flex pb-0" v-if="hasInvestigator">
            <v-switch v-model="isActiveSmartData"
                      class="simple-switch"
                      label="Ativar dados inteligentes?"
                      @click="setSmartData" />
          </v-flex> -->
          <v-flex
            xs12
            sm6
            class="full-flex">
            <display-field
              v-if="isDisplayMode"
              label="Inscrição do Imóvel"
              :value="items.propertyRegistration" />
            <div v-else>
              <v-text-field
                v-if="actionsEdit"
                v-model="dataItems.propertyRegistration"
                label="Inscrição do Imóvel"
                disabled />
              <v-text-field
                v-else
                ref="propertyRegistration"
                v-model="dataItems.propertyRegistration"
                v-mask="mask.propertyRegistration"
                label="Inscrição do Imóvel"
                data-cy="userPropertyRegistration"
                :loading="loading"
                @blur="blurField" />
            </div>
          </v-flex>

          <v-flex
            xs12
            sm6
            class="full-flex">
            <display-field
              v-if="isDisplayMode"
              label="CPF"
              :value="items.document" />
            <div v-else>
              <v-text-field
                v-if="actionsEdit"
                v-model="dataItems.document"
                label="CPF"
                disabled />
              <v-text-field
                v-else
                ref="document"
                v-model="dataItems.document"
                v-mask="mask.cpf"
                label="CPF"
                data-cy="userCpf"
                :loading="loading"
                :rules="cpfRules"
                @blur="blurField" />
            </div>
          </v-flex>

          <v-flex
            xs12
            sm6
            class="full-flex">
            <display-field
              v-if="isDisplayMode"
              label="Data de nascimento"
              :value="items.birthDate" />
            <v-text-field
              v-else
              v-model="dataItems.birthDate"
              v-mask="mask.date"
              label="Data de nascimento"
              data-cy="userBirthDate"
              :disabled="isDisabled"
              :rules="dateRules"
              masked
              @blur="blurField" />
          </v-flex>

          <v-flex
            xs12
            sm6
            class="full-flex">
            <display-field
              v-if="isDisplayMode"
              label="Nome completo"
              :value="items.name" />
            <v-text-field
              v-else
              v-model="dataItems.name"
              data-cy="userName"
              label="Nome completo"
              :disabled="isDisabled"
              :rules="requiredRules"
              @blur="blurField" />
          </v-flex>

          <v-flex
            xs12
            sm6
            class="full-flex">
            <display-field
              v-if="isDisplayMode"
              label="E-mail"
              :value="items.email" />
            <div v-else>
              <v-text-field
                v-if="actionsEdit && !emailActions"
                v-model="items.email"
                label="Email"
                disabled />
              <select-emails
                v-else
                data-cy="userEmail"
                label="Email"
                :disabled="isDisabled"
                :edit-mode="!isDisplayMode"
                :empty="isEmptyData"
                :items="emails"
                :rules="emailRules"
                :value="dataItems.email"
                @change="changeEmail"
                @blur="blurField" />
            </div>
          </v-flex>

          <v-flex
            v-if="$route.params && $route.params.group && $route.params.group == 'negotiator'"
            xs12
            sm6
            class="full-flex">
            <display-field
              v-if="isDisplayMode"
              label="Pode criar caso?"
              :value="caseCreate ? 'Sim' : 'Não'" />
            <v-checkbox
              v-else
              v-model="dataItems.caseCreate"
              :disabled="isDisabled"
              label="Pode criar caso?" />
          </v-flex>

          <v-flex
            v-if="$route.params && $route.params.group && $route.params.group == 'negotiator'"
            xs12
            sm6
            class="full-flex">
            <display-field
              v-if="isDisplayMode"
              label="Habilitar para receber novas Negociações?"
              :value="caseNegotiate ? 'Sim' : 'Não'" />
            <v-checkbox
              v-else
              v-model="dataItems.caseNegotiate"
              :disabled="isDisabled"
              label="Habilitar para receber novas Negociações?" />
          </v-flex>
        </v-layout>

        <v-btn
          v-if="phoneActions && showButtonPhone && !isDisplayMode"
          class="ml-0"
          color="accent"
          data-cy="userAddPhone"
          text
          small
          @click="addPhone">
          Adicionar telefone
        </v-btn>

        <div v-if="phoneActions || showAddPhones">
          <v-flex
            v-if="isDisplayMode"
            xs12
            sm6>
            <display-field
              v-if="hasPhones"
              label="Telefones"
              :value="items.phones" />
          </v-flex>

          <v-layout
            v-for="(phone, index) in newPhones"
            v-else
            :key="`phone-${index}`"
            class="layout-block"
            row
            wrap>
            <v-flex
              xs12
              sm6>
              <field-phone
                label="Número"
                data-cy="userPhone"
                :index="index"
                :options="phones"
                no-type
                @change="changeNewPhone" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-tooltip
                v-if="showRemoveAction(index)"
                top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    x-small
                    depressed
                    class="elevation-0 mr-2 mt-6"
                    v-on="on"
                    @click="removePhone(index)">
                    <v-icon
                      small
                      dark
                      color="error">
                      {{ $root.icons.action.remove }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Remover telefone</span>
              </v-tooltip>
              <v-tooltip
                v-if="showAddAction(index)"
                top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    x-small
                    depressed
                    class="elevation-0 mt-6"
                    v-on="on"
                    @click="addPhone">
                    <v-icon
                      small
                      dark
                      color="success">
                      {{ $root.icons.action.add }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Adicionar telefone</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <div
        class="pb-2 actions-step-fixed"
        :class="`text-${btnPosition}`">
        <v-btn
          v-if="btnCancelShow"
          title="Voltar ao passo anterior"
          class="textColor--text mr-2"
          depressed
          text
          @click.native="cancel">
          {{ btnCancelLabel }}
        </v-btn>

        <v-btn
          v-if="!hideSubmit"
          color="accent"
          data-cy="userSubmit"
          title="Ir para o próximo passo"
          :disabled="!valid"
          :loading="isLoading"
          @click="submit">
          {{ submitLabel }}
        </v-btn>
      </div>
    </v-form>
    <modal
      btn-primary-label="Quero pagar por isso"
      :show="modalSmartData"
      @close="closeModalSmart"
      @submit="saveModalSmart">
      <span slot="title">Ativar dados inteligentes?</span>
      <div slot="content">
        <p>A consulta por CPF retornará dados inteligentes facilitando o prenchimento dos campos (email, telefone e endereço)</p>
        <p>Esse benefício terá um custo de R$ XX,00 para a consulta de somente um CPF e será descontado do seu ....</p>
      </div>
    </modal>
  </v-container>
</template>

<script>
  const DatePicker = () => import('@/components/ui/DatePicker')
  const SelectEmails = () => import('@/components/ui/SelectEmails')
  const FieldPhone = () => import('@/components/ui/FieldPhone')
  const Modal = () => import('@/components/ui/Modal')
  import { states } from '@/utils'
  import formsMixin from '@/mixins/forms'
  import { mapGetters } from 'vuex'

  export default {
    components: { SelectEmails, FieldPhone, Modal },
    mixins: [ formsMixin ],
    props: {
      phoneActions: {
        type: Boolean,
        default: false
      },
      emailActions: {
        type: Boolean,
        default: false
      },
      hasInvestigator: {
        type: Boolean,
        default: false
      },
      requiredPhone: {
        type: Boolean,
        default: false
      },
      caseCreate: {
        type: Boolean,
        default: false
      },
      caseNegotiate: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      modalSmartData: false,
      acceptSmartData: false,
      showAddPhones: false,
      isActiveSmartData: false,
      changePhone: false,
      states: states,
      emails: [],
      phones: [],
      newPhones: [],
      isEmptyData: null
    }),
    computed: {
      ...mapGetters({
        companyId: 'currentCompany/id',
        loading: 'investigator/loading',
        isLoading: 'app/getLoading'
      }),
      hasPhones () {
        return this.items.phones !== undefined
      },
      showButtonPhone () {
        return this.newPhones.length === 0
      }
    },
    watch: {
      items (val) {
        this.dataItems = val

        const companiesAbilities = val.companiesAbilities || {}
        const companyAbility = companiesAbilities[this.companyId] || {}
        this.dataItems.caseCreate = companyAbility.caseCreate
        this.dataItems.caseNegotiate = companyAbility.caseNegotiate

        this.showAddPhones = this.requiredPhone
        this._checkRequirePhoneProp()
      },
      'dataItems.document' (cpf) {
        this.hasInvestigator && cpf !== null && cpf.length < 11 ? this.clearFields() : this.setNotData()
        if (this.hasInvestigator && this.cpfRules[1](cpf) === true) {
          this.getInvestigator(cpf)
        }
      },
      'dataItems.propertyRegistration' (subscription) {
        subscription.length === 20 && this.getDataByPropertyRegistration(subscription)
      },
      isEmptyData (isEmpty) {
        this.$emit('empty', isEmpty)
      },
      requiredPhone (value) {
        this.showAddPhones = this.changePhone = value
        this._checkRequirePhoneProp()
      }
    },
    mounted () {
      this.dataItems = Object.assign({}, this.items)
      this._checkRequirePhoneProp()
      this.isDisabled = this.editAction
      this.showAddPhones = this.requiredPhone
    },
    methods: {
      removePhone (index) {
        this.newPhones.splice(index, 1)
        this.dataItems.phones.splice(index, 1)
      },
      addPhone () {
        let phone = {
          areaCode: '',
          countryCode: '',
          number: '',
          type: ''
        }
        // a condicao abaixo só é verdadeira se tiver investigator funcinando
        // let options = this.phones.filter(phone => !this.dataItems.phones.includes(phone))
        // this.newPhones.push({ options })
        this.newPhones.push(phone)
      },
      showAddAction (index) {
        // return this.changePhone && this.newPhones.length === parseInt(index) + 1
        return this.newPhones.length === parseInt(index) + 1
      },
      showRemoveAction (index) {
        if (this.requiredPhone && this.newPhones.length === 1) {
          return false
        } else {
          return this.newPhones.length === index + 1
        }
      },
      saveDate (date) {
        var setDate = {birthDate: date}
        this.dataItems = Object.assign(this.dataItems, setDate)
      },
      blurField () {
        this.$emit('blur', this.dataItems)
        this.isDisplayMode = false
        // this.newPhones = Object.assign([], this.items.phones)
      },
      // setDisabledFields () {
      //   if (this.$route.name === 'CompanyNew' || this.$route.name === 'UserNew' || this.$route.name === 'CaseNew') {
      //     this.isDisabled = true
      //   }
      // },
      changeEmail (email) {
        if (!email || !email[0]) {
          return
        }
        this.dataItems.email = email[0].email ? email[0].email : email[0]
      },
      changeNewPhone (selectedPhone, index) {
        const isArray = selectedPhone.constructor === Array
        selectedPhone = isArray ? selectedPhone[0] : selectedPhone
        const isErase = selectedPhone.type === ''

        if (isErase) {
          this.dataItems.phones.splice(index, 1)
        } else {
          this.changePhone = true
          this.dataItems.phones.push(selectedPhone)
        }

        this.$emit('blur', this.dataItems)
      },
      clearFields () {
        this.acceptSmartData = false
        this.dataItems.name = ''
        this.dataItems.birthDate = ''
        this.dataItems.email = ''
        this.emails = []
        // this.newPhones = []
        this.isEmptyData = true
      },
      setNotData () {
        this.isEmptyData = false
      },
      setSmartData () {
        this.$nextTick(() => { this.modalSmartData = true })
      },
      closeModalSmart () {
        this.modalSmartData = false
        this.isActiveSmartData = this.acceptSmartData
      },
      saveModalSmart () {
        this.acceptSmartData = true
        this.closeModalSmart()
        this.$nextTick(() => { this._focusDocumentField() })
      },
      _focusDocumentField () {
        if (this.dataItems.document === '') {
          this.$refs.document.focus()
        }
      },
      isMobile(value) {
        const numbers = value.replace('+55', '').replace(/\D+/g, '').replace(/0/, '')
        const digit = parseInt(numbers[2] || 0)
        const digits = [6, 7, 8, 9]
        return digits.includes(digit)
      },
      getInvestigator (cpf) {
        this
          .$store
          .dispatch('investigator/search', cpf)
          .then(investigator => {
            this.isDisabled = false
            if (investigator && investigator.name) {
              this.dataItems.name = investigator.name
              if(investigator.birthDate){
                const birthDate = investigator.birthDate
                const formattedDate = new Date(birthDate).toISOString().substring(0, 10).replace(/-/g, '/').split('/').reverse().join('/')
                this.dataItems.birthDate = formattedDate
              }
              this.emails = investigator.emails

              this.phones = investigator.phones.map(item => {
                const regex = this.isMobile(item.number) ? /(\+\d{2})(\d{2})(\d{5})(\d+)/ : /(\+\d{2})(\d{2})(\d{4})(\d+)/
                return {...item, number: item.number.replace(regex, '$1 $2 $3-$4') }
              })

              this.$emit('investigator', investigator)
            } else {
              this.clearFields()
            }
          })
      },
      getDataByPropertyRegistration (subscription) {
        this
          .$store
          .dispatch('investigator/searchByPropertyRegistration', subscription)
          .then(investigator => {
            this.isDisabled = false

            if (investigator.recipient.document) {
              this.dataItems.document = investigator.recipient.document
            }

            this.dataItems.details = investigator.details
            this.dataItems.currentValue = investigator.currentValue
            this.dataItems.originalValue = investigator.originalValue
            this.dataItems.tickets = investigator.tickets.map(item => ({
              ...item,
              dueAt: item.dueAt.split('-').reverse().join('/')
            }))
          })
      },
      _checkRequirePhoneProp () {
        this.requiredPhone && this.addPhone()
      }
    }
  }
</script>
